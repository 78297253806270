@media (--v-from-md) {
  .how_it_works_grid {
    grid-template-areas:
      'card1 card1'
      'card2 card3';
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .how_it_works_grid > :first-child {
    grid-area: card1;
    width: 100%;
    margin: 0 auto;
  }

  .how_it_works_grid > :nth-child(2) {
    grid-area: card2;
  }

  .how_it_works_grid > :nth-child(3) {
    grid-area: card3;
  }
}
