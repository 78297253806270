.select-container {
  width: 100%;
  margin-inline-end: var(--v-space-24);
}

.select-container:last-child {
  margin-inline-end: 0;
}

.electric-legend-container {
  height: var(--v-space-24);
}

.grid-container {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: var(--v-space-24);
  padding-bottom: var(--v-space-8);
}

.grid-icon {
  flex-shrink: 0;
  width: var(--v-space-48);
  height: var(--v-space-48);
}
