.list-item,
.list-item-price-breakdown {
  height: min-content;
}

.list-item:first-child > summary {
  padding-top: 0;
}

.list-item > *:empty {
  padding: 0;
}

.list-item-accordion,
.list-item-price-breakdown-content-accordion {
  border-bottom-width: 0;
}

.list-item-content-accordion::before {
  padding-top: var(--v-space-24);
  content: '';
}

.list-item-image {
  width: 4.5rem;
  height: 4.5rem;
}

.list-item-pointer {
  cursor: pointer;
}

.list-item-default {
  cursor: default;
}

.list-item-secondary-info {
  flex: 1 1 60%;
  min-width: 0;
}

.list-item-content {
  height: auto;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

/** Just a large number to ensure the content is always visible (might need to increase this if the content is very long) */
.list-item-content-height {
  max-height: 1500px;
}

.list-item-link-price-breakdown {
  align-self: flex-end;
}
.list-item-link-card {
  text-decoration: none;
}

.list-item-link-arrow-icon {
  transition: transform 0.25s ease-in-out 0.1s;
  transform: rotate(0deg);
}

.list-item-link-card:first-child {
  padding-top: 0;
}

.list-item-link-rotate {
  transform: rotate(180deg);
}
