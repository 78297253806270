.faq-question-body div {
  margin: 0;
  color: var(--v-color-foreground-secondary);

  a {
    color: var(--v-color-foreground-primary);
    text-decoration: underline;

    &:hover {
      color: var(--v-color-foreground-secondary);
    }
  }

  table {
    text-align: start;
  }

  ol {
    padding-inline-start: var(--v-space-32);
    margin: 0;
  }
}

.search-input-container {
  position: relative;

  & input {
    padding-inline-end: var(--v-space-48);

    &::-ms-clear {
      display: none;
    }
  }
}

.search-button {
  position: absolute;
  top: 50%;
  display: flex;
  transform: translateY(-50%);
  inset-inline-end: var(--v-space-16);

  [disabled] {
    pointer-events: none;
  }
}
