.section-body div {
  a {
    color: var(--v-color-foreground-primary);
    text-decoration: 'underline';
  }

  a:hover {
    color: var(--v-color-foreground-secondary);
  }
}
