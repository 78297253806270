@keyframes background-blink {
  0%,
  50%,
  100% {
    background: transparent;
  }
  33.333%,
  66.666% {
    background: var(--v-color-ornament-primary);
  }
}
.disclaimer:target {
  animation: background-blink 2s 0.5s ease;
}

.disclaimer-list {
  padding-inline-start: 2rem;
  list-style-type: revert;
}
