@import url('@volvo-cars/css/breakpoints.css');

.inner-container {
  max-width: 1280px;
}

.image {
  object-fit: 'cover';
  aspect-ratio: 4 / 3;
}

.step-list {
  display: flex;
  flex-direction: column;
}

.step-number-text {
  width: var(--v-space-24);
  color: rgb(0 0 0 / 16%);
}

.text-and-body {
  max-width: calc(var(--v-space-8) * 36);
}

@media (--v-from-md) {
  .image {
    aspect-ratio: 21 / 9;
  }

  .step-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .step-container {
    flex: 50%;
  }
}

@media (--v-from-lg) {
  .step-list {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 calc(var(--v-space-8) * 13);
  }
}
